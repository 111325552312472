import '../styles/index.scss'

import React from 'react'
import { Home, PageWrap, SEO } from '../components'

const IndexPage = () => (
	<>
		<SEO title="Home" />
		<PageWrap>
			<Home />
		</PageWrap>
	</>
)

export default IndexPage
